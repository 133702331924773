<template>
  <section class="sectionFormulaires">
    <ul class="pl-0 list-style-none">
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('AUTO')">
          <span class="iconFormulaire">
            <span class="auto"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">Assurance Auto</h2>
        </a>
      </li>
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('MRH')">
          <span class="iconFormulaire">
            <span class="mrh"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">
            Assurance Habitation
          </h2>
        </a>
      </li>
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('SANTE')">
          <span class="iconFormulaire">
            <span class="sante"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">Mutuelle santé</h2>
        </a>
      </li>
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('PJ')">
          <span class="iconFormulaire">
            <span class="prevoyance"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">
            Protection juridique
          </h2>
        </a>
      </li>
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('IJHA')">
          <span class="iconFormulaire">
            <span class="prevoyance"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">
            Prévoyance hospitalisation
          </h2>
        </a>
      </li>
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('DCA')">
          <span class="iconFormulaire">
            <span class="vie"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">Prévoyance vie</h2>
        </a>
      </li>
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('AE')">
          <span class="iconFormulaire">
            <span class="emprunteur"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">
            Assurance emprunteur
          </h2>
        </a>
      </li>
      <li class="li-formulaire">
        <a class="w-100" @click="redirectToFormulaire('OBSEQUES')">
          <span class="iconFormulaire">
            <span class="prevoyance"></span>
          </span>
          <h2 class="font-familiy-ssp fw-300 ta-center">Assurance obsèques</h2>
        </a>
      </li>
    </ul>
  </section>
  <p
    class="pasAuthentifie-p"
    v-if="this.utilisateurPasAuthentifie && this.pasAuthentifieClick"
  >
    Vous devez d'abord sélectionner une agence
  </p>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      pasAuthentifieClick: false,
    };
  },
  props: {
    codePromo: String,
    conseillerId: String,
    utilisateurPasAuthentifie: Boolean,
  },
  methods: {
    /**
     * Méthode permettant de rediriger vers le formulaire cliqué
     * @param {String} produit
     * @return {Void}
     */
    async redirectToFormulaire(produit) {
      // Si une agence a été choisie
      try {
        if (localStorage.agencyId) {
          this.pasAuthentifieClick = false;

          // Envoi de la requête pour récupérer l'url de redirection
          const response = await axios.post(
            // 'http://localhost:8008/api/V1/extranet/publicapi/lfm/getFormulaire',
            `/secure/api/extranet/publicapi/lfm/getFormulaire`,
            {
              idAgence: localStorage.agencyId,
              codePromo: this.codePromo || "",
              produit: produit,
              codeMarque: "LFM",
              // conseillerId: this.conseillerId || ""
            },
            {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "no-cache",
            }
          );
          // Ouverture du formulaire
          console.log(response.data.url);
          window.open(response.data.url, "_self");
        } else {
          this.pasAuthentifieClick = true;
        }
      } catch (e) {
        console.log("Error : ", e)
      }
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.sectionFormulaires {
  color: #fff;
  box-sizing: border-box;
  width: 50rem;
  margin-left: 5%;
  margin-top: 20%;
}

.pl-0 {
  padding-left: 0;
}

.list-style-none {
  list-style: none;
}

.li-formulaire {
  margin-bottom: 1rem;
  margin-right: 40%;
  background-color: rgba(0, 160, 240, 0.8);
  height: 4.2rem;
  padding-left: 1rem;
  display: flex;
}

.li-formulaire:hover {
  background-color: #00a0f0;
  text-decoration: underline;
  cursor: pointer;
}

.iconFormulaire {
  margin-top: 3px;
  float: left;
  vertical-align: bottom;
  width: 60px;
  border-radius: 120px;
  background-color: #e9ecef;
  height: 60px;
}

.iconFormulaire > span {
  display: inline-block;
  z-index: 99;
  width: 60px;
  height: 60px;
}

.pasAuthentifie-p {
  color: orange;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem 2rem;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.h2-r2 {
  font-weight: 300;
  text-align: center;
}

.fw-300 {
  font-weight: 300;
}

.ta-center {
  text-align: center;
}

.iconFormulaire > span.auto {
  background: url("../assets/site/picto/produit_60.png") no-repeat -5px -5px;
}

.iconFormulaire > span.mrh {
  background: url("../assets/site/picto/produit_60.png") no-repeat -5px -75px;
}

.iconFormulaire > span.emprunteur {
  background: url("../assets/site/picto/produit_60.png") no-repeat -75px -5px;
}

.iconFormulaire > span.sante {
  background: url("../assets/site/picto/produit_60.png") no-repeat -145px -75px;
}

.iconFormulaire > span.prevoyance {
  background: url("../assets/site/picto/produit_60.png") no-repeat -145px -5px;
}

.iconFormulaire > span.vie {
  height: 60px;
  margin-top: 12px;
  background: url("../assets/site/picto/sansBorder/picto-autres-protections.png")
    no-repeat;
}

/* Si l'écran possède une longueur comprise entre 768 et 1200 pixels */
@media (min-width: 768px) and (max-width: 1200px) {
  .pasAuthentifie-p {
    font-size: 1.5rem;
  }

  .font-familiy-ssp {
    margin-top: 0.5rem;
    font-size: large;
  }

  .iconFormulaire {
    width: 0;
    height: 0;
  }

  .li-formulaire {
    height: 2rem;
    padding-left: 0;
  }

  .iconFormulaire > span.auto {
    background: none;
  }

  .iconFormulaire > span.mrh {
    background: none;
  }

  .iconFormulaire > span.emprunteur {
    background: none;
  }

  .iconFormulaire > span.sante {
    background: none;
  }

  .iconFormulaire > span.prevoyance {
    background: none;
  }

  .iconFormulaire > span.vie {
    background: none;
  }
}

/* Si l'écran possède une longueur comprise entre 767 et 1036 pixels */
@media (min-width: 767px) and (max-width: 1036px) {
  .pasAuthentifie-p {
    margin-right: 20%;
  }
}

/* Si l'écran possède une longueur inférieure à 767 pixels */
@media (max-width: 767px) {
  .sectionFormulaires {
    margin-left: 0;
  }

  .font-familiy-ssp {
    margin-top: 0.5rem;
    font-size: x-large;
  }

  .iconFormulaire {
    width: 0;
    height: 0;
  }

  .li-formulaire {
    height: 3rem;
    padding-left: 0;
    margin-right: 30%;
  }

  .iconFormulaire > span.auto {
    background: none;
  }

  .iconFormulaire > span.mrh {
    background: none;
  }

  .iconFormulaire > span.emprunteur {
    background: none;
  }

  .iconFormulaire > span.sante {
    background: none;
  }

  .iconFormulaire > span.prevoyance {
    background: none;
  }

  .iconFormulaire > span.vie {
    background: none;
  }

  .pasAuthentifie-p {
    font-size: 1.5rem;
  }
}

/* Si l'écran possède une longueur inférieure à 365 pixels */
@media (max-width: 365px) {
  .li-formulaire {
    margin-right: 60%;
  }
}

/* Si l'écran possède une longueur inférieure à 310 pixels */
@media (max-width: 310px) {
  .ta-center {
    text-align: unset;
  }

  .font-familiy-ssp {
    font-size: large;
  }
}
</style>

<template>
  <section class="h-100" id="accueil">
    <article class="article-video">
      <video autoplay loop muted class="video-background">
        <source src="../assets/mp4/bg.mp4" >
      </video>
    </article>
    <article class="overlay"></article>
    <article class="masthead">
      <article class="masthead-bg"></article>
      <div class="container h-100 ml-auto">
        <div class="row h-100">
          <div class="col-12 my-auto pl-agences">
            <Agences
              @codePromo="setCodePromo"
              @utilisateurPasAuthentifie="setUtilisateurPasAuthentifie"
            >
            </Agences>
          </div>
        </div>
      </div>
    </article>
    <article class="formulaire-icons">
      <div class="container h-100 ml-50">
        <div class="row h-100">
          <div class="col-12 my-auto pl-formulaires">
            <Formulaires
              :codePromo="codePromo"
              :utilisateurPasAuthentifie="utilisateurPasAuthentifie"
            >
            </Formulaires>
          </div>
        </div>
      </div>
    </article>
  </section>
  <section id="veille">
    <img v-for="image in this.imagesVeille" :id="image.id" class="img-veille" :key="image.id" :src="image.url" alt="imageVeille">
  </section>
</template>

<script>
import Agences from './Agences';
import Formulaires from './Formulaires';
export default {
  components: { Agences, Formulaires },
  data() {
    // A décommenter pour remettre en place la veille
    /*
    // Récupération des images de veille
    // Création d'un tableau à partir des images en attribuant un id et une url
    const imagesVeille = require.context('../assets/veille', true, /^.*\.png$/);
    this.imagesVeille = imagesVeille.keys().map(image => {
      return {
        id: imagesVeille.keys().indexOf(image),
        url: imagesVeille(image),
        conseillerId: null
      }
    });
    // Appel de la méthode de gestion de la veille
    this.inactivityTime();
    */
    return {
      codePromo: '',
      utilisateurPasAuthentifie: false,
    }
  },
  methods: {
    /**
     * Méthode récupérant la valeur du code promo transmise par le component enfant "Agences.vue"
     * @param {String} codePromoTransmis
     * @return {Void}
     */
    setCodePromo (codePromoTransmis) {
      this.codePromo = codePromoTransmis;
    },
    /**
     * Méthode permettant de récupérer la valeur de l'authentification de l'utilisateur
     * @param {Boolean} formulaireSanteMasqueTransmis
     * @return {Void}
     */
    setUtilisateurPasAuthentifie (utilisateurPasAuthentifie) {
      this.utilisateurPasAuthentifie = utilisateurPasAuthentifie;
    },
    /**
     * Méthode appliquée lorsque l'utilisateur est inactif
     * @return {Void}
     */
    inactivityTime() {
      let time;
      let index = 0;
      // Récupération des images du dossier d'images de veille
      const imagesVeille = require.context('../assets/veille', true, /^.*\.png$/);

      // Préparation du timer à l'arrivée sur la page
      window.onload = resetTimer;

      // Si la souris bouge, si le clavier est utilisé ou si la souris clique, le timer est reset
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      document.onclick = resetTimer;

      function setVeille() {
        // La veille n'intervient uniquement que sur les agences récentes listées dans "listIdRecentAgency"
        const listIdRecentAgency = ['1054', '1055', '1036', '1043', '1006', '1020', '1035', '1049', '1039', '1062', '1034', '1037', '1137', '1019', '1021', '1051', '1024', '1058', '1179', '1032', '1182', '1041', '1033'];
        if (listIdRecentAgency.find(element => element === localStorage.agencyId)) {
          // Déclenchement de la veille : modification de l'affichage
          document.getElementById('veille').style.display = 'block';
          document.getElementById('accueil').style.display = 'none';

          index++;
          // On repart à la première image si la dernière image de la liste a été affichée
          if (index === imagesVeille.keys().length) {
            index = 0;
          }
          // Masquage des différentes images de veille
          let indexImages = 0;
          while(indexImages < imagesVeille.keys().length) {
            document.getElementById(indexImages).style.display = 'none';
            indexImages++;
          }
          // affichage de l'image correspondant à la variable "index"
          document.getElementById(index).style.display = 'block';
        }
        // Appel de la méthode "setVeille" après délai d'une minute
        time = setTimeout(setVeille, 60000);
      }

      function resetTimer() {
        // Reset du timer : modification de l'affichage
        document.getElementById('veille').style.display = 'none';
        document.getElementById('accueil').style.display = 'block';
        // Timer remis à zéro
        clearTimeout(time);
        // Appel de la méthode "setVeille" après délai d'une minute 
        time = setTimeout(setVeille, 60000);
      }
    },
  }
}
</script>

<style scoped>
.formulaire-icons {
  z-index: 1;
  position: absolute;
  margin-bottom: 2rem;
  width: 100%;
}

.article-video {
  position: absolute;
  z-index: -1;
  inset: 0px;
  overflow: hidden;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
}

.video-background {
  margin: auto;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
  width: auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  height: 100%;
  min-height: 35rem;
  width: 100%;
  background-color: #e9ecef;
  opacity: .75;
  z-index: -1;
  box-sizing: border-box;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
}
.pl-agences {
  padding-left: 15px;
}

.pl-formulaires {
  padding-left: 15px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.masthead {
  z-index: 9000;
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem;
}

.ml-50 {
  margin-left: 50%;
}

.ml-auto {
  margin-left: auto;
}

.masthead-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-height: 35rem;
  height: 100%;
  background-color: rgba(0,160,240,.8);
  transform-origin: bottom right;
}

.h-100 {
  height: 100%;
}

.img-veille {
  left: 0;
  top: 0;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  display: none;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* Si l'écran possède une longueur supérieure à 1200 pixels */
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* Si l'écran possède une longueur supérieure à 992 pixels */
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

/* Si l'écran possède une longueur supérieure à 768 pixels */
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .masthead {
    height: 100%;
    min-height: 0;
    width: 40.5rem;
    padding-bottom: 0;
  }

  .masthead-bg {
    min-height: 0;
    transform: skewX(-8deg);
    transform-origin: top right;
  }

  .formulaire-icons {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
  }
}

/* Si l'écran possède une longueur supérieure à 576 pixels */
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

/* Si l'écran possède une longueur inférieure à 767 pixels */
@media (max-width: 767px) {
  .ml-50 {
    margin-left: auto;
  }
}

/* Si l'écran possède une longueur inférieure à 510 pixels */
@media (max-width: 510px) {
  .pl-formulaires {
    padding-left: 0;
  }
}
</style>

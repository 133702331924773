<template>
  <section class="masthead-content text-white py-5 py-md-0">
    <h1 class="mb-3">Agence LFM</h1>
    <section v-if="this.choixAgence">
      <p class="mb-5 input-group-agence">
        Veuillez saisir votre identifiant Agence !
      </p>
      <article class="display-flex input-group-agence">
        <select v-model="selected" class="custom-select" id="agenceIdSelect">
          <option
            v-for="option in options"
            :key="option.key"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <button
          class="btn btn-secondary"
          type="button"
          @click="choisirAgence()"
        >
          CHOISIR
        </button>
      </article>
    </section>
    <p v-if="this.userInconnu">Cet identifiant n'est pas reconnu !</p>
    <!-- <section v-if="!this.choixConseiller && false">
      <p class="mb-5 input-group-agence">Veuillez saisir votre conseiller !</p>
      <article class="display-flex input-group-agence">
        <select
          v-model="selectedConseillerId"
          class="custom-select"
          id="agenceIdSelect"
        >
          <option
            v-for="(option, i) in optionsConseiller"
            :key="i"
            :value="option.id_cm"
          >
            {{ option.prenom }} {{ option.nom }}
          </option>
        </select>
        <button
          class="btn btn-secondary"
          type="button"
          @click="choisirConseiller()"
        >
          CHOISIR
        </button>
      </article>
    </section> -->
    <section class="mt-3">
      <article class="form-group" v-if="this.codePromoChoisi">
        <label>Code Promotion</label>
        <input
          type="text"
          class="inputCodePromo"
          id="codePromoChoisi"
          @change="sauvegarderCodePromo()"
          aria-describedby="codePromoChoisi"
          placeholder="Saisir votre code promotion"
        />
        <span class="form-text text-grey">Si vous en avez un !</span>
      </article>
      <p class="mb-5" v-if="this.agencePrinted">
        Vous êtes à l'<b>{{ this.agence }}</b> !
        <a class="a-modifierAgence" @click="modifierAgence()">Modifier</a>
      </p>
      <!-- <p class="mb-5" v-if="this.selectedConseillerName">
        <b>{{ this.selectedConseillerName }}</b> est votre conseiller !
        <a class="a-modifierAgence" @click="modifierConseiller()">Modifier</a>
      </p> -->
    </section>
  </section>
</template>

<script>
import axios from "axios";
export default {
  props: {
    codePromo: String,
    utilisateurPasAuthentifie: Boolean,
  },
  data: function () {
    // Affectation de "utilisateurPasAuthentifie" en fonction du fait qu'une agence a été gardée dans le localStorage ou non
    const agenceChoisie = localStorage.agencyId ? false : true;
    this.$emit("utilisateurPasAuthentifie", agenceChoisie);

    return {
      choixAgence: localStorage.agencyId ? false : true,
      choixConseiller: localStorage.getItem("choixConseiller") || false,
      codePromoChoisi: localStorage.agencyId ? true : false,
      agencePrinted: localStorage.agencyId ? true : false,
      userInconnu: false,
      selected: localStorage.agencyId || "0",
      agence: localStorage.agencyName || "",
      options: [],
      selectedConseillerId: localStorage.getItem("conseillerId") || null,
      selectedConseillerName: localStorage.getItem("conseillerName") || null,
      optionsConseiller: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        "/secure/api/annuaire/agences"
      );
      this.options = response.data;
    } catch (e) {
      console.log("Error getAgences : ", e);
    }
    // try {
    //   const response = await axios.get(
    //     "http://localhost:8008/api/V1/annuaire/conseillers"
    //   );
    //   this.optionsConseiller = response.data;
    // } catch (e) {
    //   console.log("Error getConseillers : ", e);
    // }
  },
  methods: {
    choisirConseiller() {
      console.log("conseillerId", this.selectedConseillerId);
      const conseiller = this.optionsConseiller.find(
        (conseil) => conseil.id_cm === this.selectedConseillerId
      );
      if (conseiller) {
        this.selectedConseillerName = `${conseiller.prenom} ${conseiller.nom}`;
        this.choixConseiller = true;

        localStorage.setItem("conseillerId", this.selectedConseillerId);
        localStorage.setItem("conseillerName", this.selectedConseillerName);
        localStorage.setItem("choixConseiller", true);
      }
    },
    /**
     * Méthode appliquée lors du choix de l'agence
     * @return {Void}
     */
    choisirAgence() {
      // Si l'agence choisie est l'agence "0" ("Choisissez votre agence")
      if (this.selected === "0") {
        this.userInconnu = true;
        return;
      }

      // Enregistrement des informations de l'agence dans le localStorage
      localStorage.setItem(
        "agencyId",
        document.getElementById("agenceIdSelect").value
      );
      localStorage.setItem(
        "agencyName",
        document.querySelector("#agenceIdSelect").selectedOptions[0].text
      );

      // Transmission au component parent "Accueil.vue" au sujet du fait que l'utilisateur est authentifié
      this.$emit("utilisateurPasAuthentifie", false);

      // Affectation des variables utilisées pour l'affichage des éléments du component
      this.agence = localStorage.agencyName;
      this.userInconnu = false;
      this.choixAgence = false;
      this.codePromoChoisi = true;
      this.agencePrinted = true;
    },
    /**
     * Méthode appliquée lorsque l'utilisateur souhaite modifier l'agence
     * @return {Void}
     */
    modifierConseiller() {
      this.choixConseiller = false;
      localStorage.removeItem("conseillerId");
      localStorage.removeItem("conseillerName");
      localStorage.removeItem("choixConseiller");
    },
    modifierAgence() {
      // Suppression des valeurs de l'agence enregistrées dans le localStorage
      localStorage.removeItem("agencyId");
      localStorage.removeItem("agencyName");

      // Transmission au component parent "Accueil.vue" au sujet du fait que l'utilisateur n'est pas authentifié
      this.$emit("utilisateurPasAuthentifie", true);

      // Affectation des variables utilisées pour l'affichage des éléments du component
      this.userInconnu = false;
      this.choixAgence = true;
    },
    /**
     * Méthode appliquée lors de l'écriture du code de promotion
     * @return {Void}
     */
    sauvegarderCodePromo() {
      // Transmission du code promo au component parent "Accueil.vue"
      this.$emit("codePromo", document.getElementById("codePromoChoisi").value);
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 3rem;
}

.text-white {
  color: #fff;
}

.mb-5 {
  margin-bottom: 3rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.text-grey {
  color: #6c757d;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.inputCodePromo {
  display: block;
  width: 90%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select {
  height: 3rem;
  display: inline-block;
  width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: bottom;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.masthead .masthead-content .input-group-agence button {
  font-size: 0.8rem;
  font-weight: 700;
  padding: 1rem;
}

.masthead .masthead-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

.btn-secondary {
  color: #fff;
  background-color: #2782c4;
  border-color: #2782c4;
}

.btn-secondary:hover {
  background-color: #1f6699;
  cursor: pointer;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.a-modifierAgence {
  color: #007bff;
  text-decoration: none;
}

.a-modifierAgence:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Si l'écran possède une longueur supérieure à 768 pixels */
@media (min-width: 768px) {
  .masthead .masthead-content {
    padding-right: 8rem;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0;
  }

  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}

/* Si l'écran possède une longueur inférieure à 550 pixels */
@media (max-width: 550px) {
  .custom-select {
    width: 70%;
  }

  .inputCodePromo {
    width: 70%;
  }
}
</style>

<template>
  <Accueil />
</template>

<script>
import Accueil from './components/Accueil.vue'

export default {
  name: 'App',
  components: {
    Accueil
  },
}
</script>

<style>
#app {
  position: absolute;
  z-index: -1;
  inset: 0px;
  overflow: hidden;
}

/* Si l'écran possède une hauteur inférieure à 820 pixels */
@media (max-height: 820px) {
  #app {
    overflow-y: auto;
  }
}

/* Si l'écran possède une longueur inférieure à 767 pixels */
@media (max-width: 767px) {
  #app {
    overflow-y: auto;
  }
}
</style>
